/*-------------------------------------------*/
/*----------IMPORTS AND INITIALIZERS---------*/
/*-------------------------------------------*/

// Imports
import {
    Grid, IconButton, Toolbar,
    Tooltip, Typography, useMediaQuery, useTheme,
    Menu, MenuItem, ListItemIcon
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { NotificationsNoneOutlined, Person, PersonOutline } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import storage from "../utils/storage";

// Part of type definition for props
interface Props {
    title: string
    open: boolean
    handleDrawerOpen: () => void
    name?: string | undefined
}

/*------------------------------------------*/
/*---------------MAIN COMPONENT-------------*/
/*------------------------------------------*/

const Header: React.FC<Props> = ({ title, open, handleDrawerOpen, name }) => {

    // For navigating between pages
    const navigate = useNavigate()

    const theme = useTheme();

    // Screen size
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));

    // For user settings dialog window
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openAnchor = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = () => {
        storage.removeItem(process.env.REACT_APP_USER_DATA)
        storage.removeItem('tokens')
        storage.removeItem('userDetail')
        navigate('/Login')
    }

    const userSettingsMenu = (

        <div>
            {/* Toggle warning dialog window */}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openAnchor}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    handleLogOut()
                }}>
                    <ListItemIcon>
                        <Person fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )

    return (
        <>
            {openAnchor && userSettingsMenu}
            <Toolbar>
                {
                    isMedium &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            color: '#1e1d31',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                }
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography
                            variant="h5"
                            noWrap
                            component="div"
                            sx={{
                                color: '#1e1d31',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClick} sx={{ bgcolor: '#F2F2F2' }}>
                            {
                                name ?
                                    <div style={{ fontSize: '22px' }}>{name}</div> :
                                    <PersonOutline />
                            }
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </>
    );
}

export default Header;