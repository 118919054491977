import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    CircularProgress,
    Paper,
} from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

import { CONFIG } from '../../utils/config';
import requestHandler from '../../utils/requestHandler';
import Layout from '../Layout';

interface HistoricalFile {
    id: number;
    fileName: string;
    fileUrl: string;
    status: string;
    createdAt: string;
}

interface Props {
    open: boolean,
    setOpen: (bool: boolean) => void
}

const ListHistoricalFiles: React.FC<Props> = ({ open, setOpen }) => {

    const navigate = useNavigate();

    const [files, setFiles] = useState<HistoricalFile[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const fetchFiles = async () => {
        const { HISTORICAL_FILES } = CONFIG.DATA_HANDLER;
        try {
            const result = await requestHandler('GET', HISTORICAL_FILES, true);
            if (!result.hasError) {
                setFiles(result.response.data);
            } else if (result.status === 401) {
                navigate('/Login');
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error fetching files:', err);
            setError('Failed to fetch files. Please try again later.');
        } finally {
            setLoading(false);
        }
    }

    const handleDownload = (fileUrl: string) => {
        window.open(fileUrl, '_blank');
    };

    useEffect(() => {
        fetchFiles();
    }, [])

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Layout heading={'Historical Files'} open={open} setOpen={setOpen}>
            <Box>
                {error &&
                    <Typography color="error" align="center" sx={{ mt: 4 }}>
                        {error}
                    </Typography>
                }
                <Paper>
                    <TableContainer sx={{ margin: 'auto' }}>
                        <Table stickyHeader aria-label="historical files table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.length > 0 ? (
                                    files.map((file) => (
                                        <TableRow key={file.id} hover>
                                            <TableCell>
                                                <span
                                                    title={file.fileName}
                                                    style={{ cursor: 'default' }}
                                                >
                                                    {file.fileName.length > 50 ? (
                                                        `${file.fileName.substring(0, 40)}...`
                                                    ) : (
                                                        file.fileName
                                                    )}
                                                </span>
                                            </TableCell>
                                            <TableCell>{file.status}</TableCell>
                                            <TableCell>{new Date(file.createdAt).toLocaleString()}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleDownload(file.fileUrl)}
                                                    endIcon={<DownloadIcon />}
                                                    sx={{
                                                        minWidth: 20,
                                                        padding: '4px',
                                                        '& .MuiButton-endIcon': {
                                                            marginLeft: 0,
                                                        },
                                                    }}
                                                    disabled={!file.fileUrl}
                                                >
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Typography variant="body1">No historical files available</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </Layout >
    );
};

export default ListHistoricalFiles;
