import { useState } from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    IconButton
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface DropdownValue {
    id: number;
    name: string | number;
}

interface FormDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    error: string | null;
    fields: {
        name: string;
        label: string;
        value?: string | boolean | number | undefined;
        type?: 'text' | 'password' | 'checkbox' | 'email' | 'dropdown';
        dropdownValues?: DropdownValue[];
        hide?: boolean;
        disabled?: boolean;
        mandatory?: boolean;

    }[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
}

const FormDialog: React.FC<FormDialogProps> = ({
    open,
    onClose,
    title,
    error,
    fields,
    onChange,
    onSubmit,
}) => {
    const [showPassword, setShowPassword] = useState<Record<string, boolean>>({});
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleTogglePasswordVisibility = (fieldName: string) => {
        setShowPassword((prev) => ({
            ...prev,
            [fieldName]: !prev[fieldName],
        }));
    };

    const onCloseHandler = () => {
        setShowPassword({});
        setErrorMessage(null);
        onClose();
    }

    const validateFields = () => {
        const hasEmptyMandatoryFields = fields.some(field => (field.mandatory && !field.hide) && (field.value === '' || field.value === undefined));
        if (hasEmptyMandatoryFields) {
            setErrorMessage("Please fill in all required fields.");
            return false;
        }
        setErrorMessage(null);
        return true;
    };

    const handleSubmit = () => {
        if (validateFields()) {
            onSubmit();
        }
    };


    return (
        <Dialog open={open} onClose={(event, reason) => {
            if (reason === 'backdropClick') {
                return;
            }
            onCloseHandler();
        }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {(error || errorMessage) && (
                    <Typography color="error" align="center">
                        {error || errorMessage}
                    </Typography>
                )}
                {fields.map((field) => {
                    if (!field.hide) {
                        if (field.type === 'checkbox') {
                            return (
                                <FormControlLabel
                                    key={field.name}
                                    control={
                                        <Checkbox
                                            name={field.name}
                                            checked={!!field.value}
                                            onChange={onChange}
                                            color="primary"
                                        />
                                    }
                                    label={field.label}
                                />
                            );
                        } else if (field.type === 'dropdown') {
                            return (
                                <FormControl fullWidth key={field.name} margin="normal">
                                    {!field.value &&
                                        <InputLabel id={`${field.name}-label`} shrink={!!field.value}>{`${field.label} ${field.mandatory ? '*' : ''}`}</InputLabel>
                                    }
                                    <Select
                                        labelId={`${field.name}-label`}
                                        name={field.name}
                                        value={field.value || ''}
                                        onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
                                        disabled={field.disabled}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {field.dropdownValues?.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                        else if (field.type === 'password') {
                            return (
                                <TextField
                                    key={field.name}
                                    name={field.name}
                                    label={`${field.label} ${field.mandatory ? '*' : ''}`}
                                    type={showPassword[field.name] ? 'text' : 'password'}
                                    value={field.value}
                                    onChange={onChange}
                                    autoComplete="off"
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        handleTogglePasswordVisibility(field.name)
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword[field.name] ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            );
                        }
                        else {
                            return (
                                <TextField
                                    key={field.name}
                                    name={field.name}
                                    label={`${field.label} ${field.mandatory ? '*' : ''}`}
                                    type={field.type || 'text'}
                                    value={field.value}
                                    onChange={onChange}
                                    fullWidth
                                    margin="normal"
                                />
                            );
                        }
                    }
                })}

            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseHandler} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormDialog;