import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Box,
    Checkbox,
} from '@mui/material';

import { CONFIG } from '../../utils/config';
import requestHandler from '../../utils/requestHandler';
import Layout from '../Layout';

interface Partner {
    id: number;
    name: string;
    universalId: string;
    active: boolean;
}

interface Props {
    open: boolean,
    setOpen: (bool: boolean) => void
}

const ListPartners: React.FC<Props> = ({ open, setOpen }) => {
    const [partners, setPartners] = useState<Partner[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchPartners = async () => {
        setLoading(true);
        setError(null);

        const { LIST_PARTNERS } = CONFIG.ACCOUNTS;
        try {
            const result = await requestHandler('GET', LIST_PARTNERS, true);
            if (!result.hasError) {
                setPartners(result.response.data);
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error fetching partners:', err);
            setError('Failed to load partners. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartners();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Layout heading={'Partners'} open={open} setOpen={setOpen}>
            <Box>
                {error && (
                    <Typography color="error" align="center" sx={{ mt: 4 }}>
                        {error}
                    </Typography>
                )}
                <TableContainer component={Paper} sx={{ margin: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partners.map((partner) => (
                                <TableRow key={partner.id}>
                                    <TableCell>{partner.name}</TableCell>
                                    <TableCell>{partner.universalId}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

export default ListPartners;
