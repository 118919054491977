/*-------------------------------------------*/
/*----------IMPORTS AND INITIALIZERS---------*/
/*-------------------------------------------*/

// Imports
import { useNavigate } from 'react-router-dom'
import {
    Article, FolderCopy, UploadFile, ViewList, Backup, PeopleAlt
} from "@mui/icons-material";
import {
    Box, Divider,
    List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Stack, Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useEffect, useState } from 'react';

/*-------------------------------------------*/
/*------------PRE-CONFIG INTERFACES----------*/
/*-------------------------------------------*/

// Part of type definition for props
interface Props {
    drawerWidth: number,
    title: string,
    open: boolean,
    isUserSuperadmin?: boolean
}

/*------------------------------------------*/
/*-----------------COMPONENT----------------*/
/*------------------------------------------*/

const SideBar: React.FC<Props> = ({
    drawerWidth, title, open, isUserSuperadmin
}) => {

    // For navigating between pages
    const navigate = useNavigate()

    const linksList = [
        {
            key: "partners",
            displayText: "Partners",
            icon: <PeopleAlt />,
            link: "/partners"
        },
        {
            key: "upload",
            displayText: "Upload",
            icon: <UploadFile />,
            link: "/Upload"
        },
        {
            key: "historicalFiles",
            displayText: "Historical Files",
            icon: <Backup />,
            link: "/historical-files"
        },
        {
            key: "logs",
            displayText: "Audit Logs",
            icon: <ViewList />,
            link: "/logs"
        },
    ]

    // Screen size
    const theme = useTheme();

    const isSmallorLarger = useMediaQuery(theme.breakpoints.up('sm'));
    const [isViewportSmallOrLarger, setIsViewportSmallOrLarger] = useState(isSmallorLarger);

    useEffect(() => {
        setIsViewportSmallOrLarger(isSmallorLarger);
    }, [isSmallorLarger]);

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            <Box
                style={{
                    minHeight: '92vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >

                <Divider sx={{ bgcolor: 'white' }} />

                {/* Render sidebar (drawer) list items */}
                <List sx={{ marginTop: '10px' }}>
                    <Stack spacing={1} direction='column'>
                        {/* dashboard link */}
                        <ListItem
                            key={'Dashboard'}
                            disablePadding
                            sx={{
                                display: 'block',
                                '&:hover': { bgcolor: '#2d2c47' },
                                bgcolor: title === 'Dashboard' ? '#2d2c47' : '#1e1d31',
                                borderRight: title === 'Dashboard' ? '4px solid #ff4e3c' : 'none'
                            }}
                        >

                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 1, px: 2.5 }}
                                onClick={() => navigate(`/Dashboard`)}
                            >
                                <Tooltip
                                    title={'Flint Dashboard'}
                                    placement="right-start"
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                            color: '#b3b3b3'
                                        }}
                                    >
                                        <FolderCopy />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={'Flint Dashboard'} sx={{ color: '#b3b3b3' }} />
                            </ListItemButton>

                        </ListItem>

                        {/* accounts link  */}
                        <ListItem
                            key={'Accounts'}
                            disablePadding
                            sx={{
                                display: 'block',
                                '&:hover': { bgcolor: '#2d2c47' },
                                bgcolor: title === 'Accounts' ? '#2d2c47' : '#1e1d31',
                                borderRight: title === 'Accounts' ? '4px solid #ff4e3c' : 'none'
                            }}
                        >
                            <ListItemButton
                                sx={{ minHeight: 48, justifyContent: 1, px: 2.5 }}
                                onClick={() => navigate(`/Accounts`)}
                            >
                                <Tooltip
                                    title={'Accounts'}
                                    placement="right-start"
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 3,
                                            justifyContent: 'center',
                                            color: '#b3b3b3'
                                        }}
                                    >
                                        <Article />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={'Accounts'} sx={{ color: '#b3b3b3' }} />
                            </ListItemButton>
                        </ListItem>

                        {isUserSuperadmin &&
                            linksList.map(({ key, displayText, link, icon }, index) => (
                                <ListItem
                                    key={key}
                                    disablePadding
                                    sx={{
                                        display: 'block',
                                        '&:hover': { bgcolor: '#2d2c47' },
                                    }}
                                >
                                    <ListItemButton
                                        sx={{ minHeight: 48, justifyContent: 1, px: 2.5 }}
                                        onClick={() => navigate(link)}
                                    >
                                        <Tooltip
                                            title={displayText}
                                            placement="right-start"
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: 3,
                                                    justifyContent: 'center',
                                                    color: '#b3b3b3'
                                                }}
                                            >
                                                {icon}
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary={displayText} sx={{ color: '#b3b3b3' }} />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                    </Stack>
                </List>
            </Box>
        </Box>
    );
}

export default SideBar;