/*-------------------------------------------*/
/*----------IMPORTS AND INITIALIZERS---------*/
/*-------------------------------------------*/

// Imports
import {
  Avatar, Box, Button, CSSObject,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  Divider, Grid, IconButton, Theme,
  Typography, styled, useMediaQuery, useTheme
} from "@mui/material";
import axios from "axios";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/SideBar";
import Header from "../components/Header";

/*-------------------------------------------*/
/*------------PRE-CONFIG INTERFACES----------*/
/*-------------------------------------------*/

////////// START OF DRAWER AND HEADER CONFIG CODE BLOCK //////////
const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('xl')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
////////// END OF DRAWER AND HEADER CONFIG CODE BLOCK //////////

// Interface for user data type
interface UserDataType {
  username: string,
  password: string
}

// Part of type definition for props
interface Props {
  open: boolean,
  setOpen: (bool: boolean) => void
}

/*------------------------------------------*/
/*-----------------COMPONENT----------------*/
/*------------------------------------------*/

const Settings: React.FC<Props> = ({ open, setOpen }) => {

  /*--------------------*/
  /*-STATES AND METHODS-*/
  /*--------------------*/

  // For navigating between pages
  const navigate = useNavigate()

  // For checking if user is already logged in
  const [userData, setUserData] = useState<UserDataType>({ username: "", password: "" })
  useEffect(() => {

    const user = window.localStorage.getItem(`${process.env.REACT_APP_USER_DATA}`)
    if (user === null) navigate('/Login')
    else setUserData(JSON.parse(user))

    document.body.style.backgroundColor = '#f9f5f9'
    return () => {
      document.body.style.backgroundColor = ''
    }

  }, [])

  const theme = useTheme();

  // Additional drawer code
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Screen size
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const [isViewportMediumOrLarger, setIsViewportMediumOrLarger] = useState(isMedium);

  useEffect(() => {
    setIsViewportMediumOrLarger(isMedium);
  }, [isMedium]);

  useEffect(() => {
    setOpen(false)
  }, [isViewportMediumOrLarger])

  // For change password dialog window
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")

  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  const handleClickOpenPasswordDialog = () => {
    setOpenPasswordDialog(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
  };

  const handlePasswordChange = (
    username: string,
    oldPassword: string,
    newPassword: string,
    newPassword2: string
  ) => {

    if (newPassword === newPassword2) {

      const url = `https://${process.env.REACT_APP_HOST_NAME}/${process.env.REACT_APP_PASSWORD_CHANGE_ROUTE}?username=${encodeURIComponent(username)}&oldPassword=${encodeURIComponent(oldPassword)}&newPassword=${encodeURIComponent(newPassword)}`
      console.log(url)
      axios.put(url)
        .then(res => console.log(res.data))
        .catch(err => console.log(err))
    } else {
      console.log('Passwords did not match!')
    }
  }

  const changePasswordDialog = (

    <div>
      {/* Toggle warning dialog window */}
      <Fragment>
        <Dialog
          open={openPasswordDialog}
          onClose={handleClosePasswordDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Fragment>
                <Typography>Old password: </Typography>
                <Form.Control
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  style={{ borderColor: 'lightgray' }}
                />
              </Fragment>
              <Fragment>
                <Typography>Enter your new password: </Typography>
                <Form.Control
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{ borderColor: 'lightgray' }}
                />
                <Typography>Re-enter your new password: </Typography>
                <Form.Control
                  type="password"
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  style={{ borderColor: 'lightgray' }}
                />
              </Fragment>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePasswordDialog}>Cancel</Button>
          </DialogActions>
          <DialogActions>
            <Button onClick={() => {
              handlePasswordChange(
                userData?.username,
                oldPassword,
                newPassword,
                newPassword2
              )
              handleClosePasswordDialog()
            }}>Update</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  )

  /*------------------*/
  /*-RENDER COMPONENT-*/
  /*------------------*/

  return (
    <Box sx={{ display: 'flex' }}>

      {/* Render the Appbar */}
      <AppBar
        position="fixed"
        open={open}
        sx={{
          bgcolor: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid lightgray'
        }}
      >
        <Header
          title={"Settings"}
          open={open}
          handleDrawerOpen={handleDrawerOpen}
        />
      </AppBar>

      {/* Render the sidebar */}
      <Drawer variant="permanent" open={open} sx={{ '& .MuiDrawer-paper': { bgcolor: '#1e1d31' }, minHeight: '100vh' }}>
        <DrawerHeader>
          <Grid
            container
            spacing={2}
            direction='row'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid
              item
              xs={8}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={process.env.REACT_APP_SIDEBAR_LOGO}
                alt='Logo'
                style={{ width: '150px', height: '50px', objectFit: 'scale-down' }}
              />
            </Grid>
          </Grid>
          <IconButton onClick={handleDrawerClose} sx={{
            color: 'white'
          }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideBar
          drawerWidth={drawerWidth}
          title='Settings'
          open={open}
        />
      </Drawer>

      {/* Render Settings page contents */}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, mt: { xs: '76px' } }}
      >

        <Grid container spacing={2} direction='column'>
          <Grid item>
            <Avatar>
              {userData && userData.username && userData.username[0].toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography>Hello, {userData.username}!</Typography>
          </Grid>
          <Grid>
            <Typography>Would you like to change your password?</Typography>
            <Button onClick={handleClickOpenPasswordDialog}> YES </Button>
          </Grid>
        </Grid>
      </Box>
      {openPasswordDialog && changePasswordDialog}
    </Box>
  );
}

export default Settings;