import { useState } from "react";

import { Box, Button } from '@mui/material';

import Layout from '../../components/Layout';
import ListAdmin from "../../components/accounts/ListAdmin";

interface Props {
    open: boolean,
    setOpen: (bool: boolean) => void
}

const Accounts: React.FC<Props> = ({ open, setOpen }) => {

    return (
        <Layout heading={'Accounts'} open={open} setOpen={setOpen}>
            <Box sx={{ width: '100%' }}>
                {/* Tab Panels */}
                <ListAdmin />
            </Box>
        </Layout>
    )
}

export default Accounts;