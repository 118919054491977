import React, { useEffect, useState } from "react";
import {
    Button, Card, CardContent, FormControlLabel, Grid,
    Switch, Collapse,
    Box, Tooltip,
    Typography,
    IconButton
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";
import styled from 'styled-components';
import { Form } from "react-bootstrap";

// For styling react-bootstrap form.control field(s)
const StyledFormControl = styled(Form.Control)`
    height: 40px;
    border-color: lightgray;
    &:focus {
        box-shadow: none;
    }
`;

// Interface for defining filters configuration type
interface KeyValueType {
    [key: string]: any;
}

// Part of type definition for props
interface Props {
    open: boolean,
    drawerWidth: number,
    setFilters: (result: KeyValueType) => void,
}

const FiltersPanel: React.FC<Props> = ({
    open,
    drawerWidth,
    setFilters,
}) => {

    const [isExpanded, setIsExpanded] = useState(true); // state to toggle collapse
    const [tMobileNumber, setTMobileNumber] = useState("");
    const [didNumber, setDidNumber] = useState("");
    const [tMobileEntId, setTMobileEntId] = useState("");
    const [entName, setEntName] = useState("");
    const [showUnassignedFlint, setShowUnassignedFlint] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    // For sending selected filter values to server for processing, on form submission or button click
    const handleSelectedFilterValues = (event?: React.FormEvent, toggleChanged = false) => {
        // Prevent default form submission behavior
        if (event) {
            event.preventDefault();
        }

        if (!toggleChanged) {
            if (!(tMobileNumber.length > 0 || didNumber.length > 0 || tMobileEntId.length > 0 || entName.length > 0)) {
                return;
            }
        }

        setError('');
        const filterValues: { [key: string]: string } = {};

        if (tMobileNumber !== "") {
            if (tMobileNumber.length < 3) {
                setError('Minimum 3 chars required to search on flint number');
                return;
            }
            filterValues.flintNumber = tMobileNumber;
        }
        if (didNumber !== "") {
            if (didNumber.length < 3) {
                setError('Minimum 3 chars required to search on DID number');
                return;
            }
            filterValues.didNumber = didNumber;
        }
        if (tMobileEntId !== "") {
            filterValues.enterpriseId = tMobileEntId;
        }
        if (entName !== "") {
            filterValues.enterpriseName = entName;
        }
        filterValues.showUnassignedFlint = String(showUnassignedFlint);

        // Only set filters if there are any non-empty values
        if (Object.keys(filterValues).length > 0) {
            setFilters(filterValues);
        }
    };

    useEffect(() => {
        handleSelectedFilterValues(undefined, true);
    }, [showUnassignedFlint]);

    return (
        <Card
            sx={{
                boxShadow: 'none',
                border: "1px solid lightgray",
                maxWidth: open ? `calc(100vw - ${drawerWidth}px)` : `calc(200vw - ${drawerWidth}px)`
            }}
        >
            <CardContent sx={{ paddingBottom: '16px !important' }}>
                {/* Header with collapse toggle */}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Filters</Typography>
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>

                {/* collapsible content */}
                <Collapse in={isExpanded}>
                    {/* Render form */}
                    <form onSubmit={handleSelectedFilterValues} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                        {/* Render filter options */}
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Form.Group controlId="tMobileNumber">
                                    <StyledFormControl
                                        type="number"
                                        value={tMobileNumber}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 50) {
                                                setTMobileNumber(e.target.value)
                                            }
                                        }}
                                        placeholder="Enter Billing Number"
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Group controlId="didNumber">
                                    <Tooltip title="Enter Desk Number or DID Number" placement="top" arrow>
                                        <StyledFormControl
                                            type="number"
                                            value={didNumber}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 50) {
                                                    setDidNumber(e.target.value);
                                                }
                                            }}
                                            placeholder="Enter Desk Number or DID Number"
                                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                        /></Tooltip>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Group controlId="tMobileEntId">
                                    <StyledFormControl
                                        type="string"
                                        value={tMobileEntId}
                                        onChange={(e) => setTMobileEntId(e.target.value)}
                                        placeholder="Enter Customer ID"
                                        maxLength={50}
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={3}>
                                <Form.Group controlId="entName">
                                    <StyledFormControl
                                        type="string"
                                        value={entName}
                                        onChange={(e) => setEntName(e.target.value)}
                                        placeholder="Enter Customer Name"
                                        maxLength={80}
                                    />
                                </Form.Group>
                            </Grid>
                        </Grid>
                        {/* Render button to filter and fetch data */}
                        <Grid container spacing={2} justifyContent="flex-between">
                            <p style={{ color: 'red', padding: '30px', paddingBottom: '0px' }}>{error}</p>
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                <Grid item sx={{ marginLeft: '20px' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showUnassignedFlint}
                                                onChange={(e) => setShowUnassignedFlint(e.target.checked)}
                                            />
                                        }
                                        label="Unassigned Billing Numbers"
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                className="fw-bold"
                                                sx={{
                                                    color: 'white',
                                                    borderColor: '#2d2c47',
                                                    '&:hover': { color: 'white', borderColor: '#2d2c47' },
                                                    paddingTop: '3px',
                                                    paddingBottom: '3px',
                                                }}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setTMobileNumber("");
                                                    setDidNumber("");
                                                    setTMobileEntId("");
                                                    setEntName("");
                                                    setFilters({});
                                                    setShowUnassignedFlint(false);
                                                    setError("");
                                                }}
                                                className="fw-bold"
                                                sx={{
                                                    color: 'white',
                                                    borderColor: '#2d2c47',
                                                    '&:hover': { color: 'white', borderColor: '#2d2c47' },
                                                    paddingTop: '3px',
                                                    paddingBottom: '3px',
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Collapse>

            </CardContent>
        </Card>
    );
};

export default FiltersPanel;
