// Imports
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import storage from "../utils/storage";
import Layout from "../components/Layout";
import { Button, CircularProgress } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import requestHandler from "../utils/requestHandler";
import { CONFIG } from "../utils/config";


// Part of type definition for props
interface Props {
    open: boolean,
    setOpen: (bool: boolean) => void
}

const Upload: React.FC<Props> = ({ open, setOpen }) => {

    // For navigating between pages
    const navigate = useNavigate()

    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

    const [recordID, setRecordID] = useState<number>();
    const [polling, setPolling] = useState<boolean>(false);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    // Handle file upload
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files ? event.target.files[0] : null;
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setUploadInProgress(false);
        setError('')

        if (!file) {
            setError('Please select a file to upload');
            return;
        }
        setLoading(true);

        // Prepare the form data to send to the API
        const formData = new FormData();
        formData.append('file', file);

        const { UPLOAD_FILE } = CONFIG.DATA_HANDLER;

        try {
            const result = await requestHandler('POST', UPLOAD_FILE, true, true, formData);
            if (!result.hasError && result.status == 201) {
                navigate('/Dashboard');
            } else if (result.status == 200) {
                setUploadInProgress(true);
                setRecordID(result.response.data.id);
                setPolling(true)
            } else if (result.status === 401) {
                navigate('/Login')
            } else {
                setError(result.message)
            }
        }
        catch (error) {
            setError("File upload failed. Please try again later.")
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval>;
        let elapsedTime = 0;
        const maxTime = 2.5 * 60 * 1000;
        const pollingInterval = 3000;

        const fetchData = async () => {
            const { HISTORICAL_FILE_BY_ID } = CONFIG.DATA_HANDLER;
            try {
                const { hasError, response, status } = await requestHandler('GET', `${HISTORICAL_FILE_BY_ID}/${recordID}`, true, true);
                if (!hasError) {
                    if (response.data.status === "COMPLETED") {
                        clearInterval(intervalId);
                        setPolling(false);
                        navigate('/Dashboard');
                    } else if (response.data.status === "FAILED") {
                        clearInterval(intervalId);
                        setPolling(false);
                        setError('File upload failed.')
                        setUploadInProgress(false);
                    }
                }
            } catch (error) {
                clearInterval(intervalId);
                setError('Failed to get status. Contact Support')
            }
        };

        if (polling) {
            fetchData();
            intervalId = setInterval(() => {
                elapsedTime += pollingInterval;
                if (elapsedTime >= maxTime) {
                    console.log("Polling stopped: Max time reached");
                    clearInterval(intervalId);
                    setPolling(false);
                    setError('Unable to obtain status. Please try again')
                    setUploadInProgress(false);
                } else {
                    fetchData();
                }
            }, pollingInterval);
        }

        return () => clearInterval(intervalId);
    }, [polling]);

    // redirect to login page if tokens not present 
    useEffect(() => {
        const tokens = storage.getItem('tokens');
        if (tokens == null) navigate('/Login');

        document.body.style.backgroundColor = '#f9f5f9'
        return () => {
            document.body.style.backgroundColor = ''
        }
    }, [])

    return (
        <Layout heading={'Upload'} open={open} setOpen={setOpen}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '10%' }}>
                <form onSubmit={handleSubmit} style={{ maxWidth: 'fit-content', margin: 'auto', textAlign: 'center' }}>
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload file
                        <VisuallyHiddenInput
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                    </Button>

                    {/* Display selected file name */}
                    {file && (
                        <div style={{ marginTop: '10px' }}>
                            <strong>Selected file: </strong> {file.name}
                        </div>
                    )}

                    {/* Info text for CSV file upload */}
                    <div style={{ marginTop: '10px', color: 'gray', fontSize: '12px' }}>
                        <strong>Note: </strong> Only CSV files should be uploaded.
                    </div>

                    {/* Submit Button */}
                    <div className="mt-4">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!file || loading}
                        >
                            {loading ? <CircularProgress size={20} /> : "Submit"}
                        </Button>
                    </div>
                </form>

                {/* display upload in progress  */}
                {
                    uploadInProgress && <div style={{ marginTop: '50px', marginBottom: '50px', margin: 'auto', color: 'green' }}>
                        <strong>Uploading...</strong>
                        <p style={{ color: 'black', marginTop: '10px' }}>Please stay on this page until the upload is complete</p>
                    </div>
                }

                {/* display errors  */}
                {
                    error && <div style={{ marginTop: '50px', marginBottom: '50px', margin: 'auto', color: 'red' }}>
                        <strong>Error: </strong> {error}
                    </div>
                }
            </div>
        </Layout>
    )
}

export default Upload;