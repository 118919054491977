// Footer.tsx
import React from 'react';
import { Box, Typography, useTheme, Link } from '@mui/material';

const Footer: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                bgcolor: theme.palette.primary.main,
                color: 'white',
                padding: '10px 20px',
                textAlign: 'center',
                left: 0,
                width: '100%',
                zIndex: theme.zIndex.drawer + 1,
            }}
        >
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
                Powered by{' '}
                <Link
                    href="https://movius.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'white', fontWeight: 'bold', textDecoration: 'none' }}
                >
                    MOVIUS
                </Link>{' '}
                Copyright © Movius Corp. All rights reserved. Movius and associated logos are trademarks of Movius Corp.<br />
                Software Version: 1.0.1
            </Typography>
        </Box>
    );
};

export default Footer;
