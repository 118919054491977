/*-------------------------------------------*/
/*----------IMPORTS AND INITIALIZERS---------*/
/*-------------------------------------------*/

// Imports
import {
  Box, CSSObject, Divider, Grid,
  IconButton, Theme, Typography,
  styled, useMediaQuery, useTheme
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/SideBar";
import Header from "../components/Header";

/*-------------------------------------------*/
/*------------PRE-CONFIG INTERFACES----------*/
/*-------------------------------------------*/

////////// START OF DRAWER AND HEADER CONFIG CODE BLOCK //////////
const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('xl')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
////////// END OF DRAWER AND HEADER CONFIG CODE BLOCK //////////

// Interface for user data type
interface UserDataType {
  username: string,
  password: string
}

// Part of type definition for props
interface Props {
  open: boolean,
  setOpen: (bool: boolean) => void
}

/*------------------------------------------*/
/*-----------------COMPONENT----------------*/
/*------------------------------------------*/

const Reports: React.FC<Props> = ({ open, setOpen }) => {

  // For navigating between pages
  const navigate = useNavigate()

  // For checking if user is already logged in
  const [userData, setUserData] = useState<UserDataType>({ username: "", password: "" })
  useEffect(() => {

    const user = window.localStorage.getItem(`${process.env.REACT_APP_USER_DATA}`)
    if (user === null) navigate('/Login')
    else setUserData(JSON.parse(user))

    document.body.style.backgroundColor = '#f9f5f9'
    return () => {
      document.body.style.backgroundColor = ''
    }

  }, [])

  const theme = useTheme();

  // Additional drawer code
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Screen size
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const [isViewportMediumOrLarger, setIsViewportMediumOrLarger] = useState(isMedium);

  useEffect(() => {
    setIsViewportMediumOrLarger(isMedium);
  }, [isMedium]);

  useEffect(() => {
    setOpen(false)
  }, [isViewportMediumOrLarger])

  /*------------------*/
  /*-RENDER COMPONENT-*/
  /*------------------*/

  return (
    <Box sx={{ display: 'flex' }}>

      {/* Render the Appbar */}
      <AppBar
        position="fixed"
        open={open}
        sx={{
          bgcolor: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid lightgray'
        }}
      >
        <Header
          title={"Reports"}
          open={open}
          handleDrawerOpen={handleDrawerOpen}
        />
      </AppBar>

      {/* Render the sidebar */}
      <Drawer variant="permanent" open={open} sx={{ '& .MuiDrawer-paper': { bgcolor: '#1e1d31' } }}>
        <DrawerHeader>
          <Grid
            container
            spacing={2}
            direction='row'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Grid
              item
              xs={8}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={process.env.REACT_APP_SIDEBAR_LOGO}
                alt='Logo'
                style={{ width: '150px', height: '50px', objectFit: 'scale-down' }}
              />
            </Grid>
          </Grid>
          <IconButton onClick={handleDrawerClose} sx={{
            color: 'white'
          }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideBar
          drawerWidth={drawerWidth}
          title='Reports'
          open={open}
        />
      </Drawer>

      {/* Render Reports page contents */}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, mt: { xs: '76px' } }}
      >
        <Typography>
          Coming soon
        </Typography>
      </Box>

    </Box>
  );
}

export default Reports