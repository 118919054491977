const storage = {
    // Save an item to storage
    setItem: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },

    // Retrieve an item from storage
    getItem: (key) => {
        return localStorage.getItem(key);
    },

    // Remove an item from storage
    removeItem: (key) => {
        localStorage.removeItem(key);
    },

    // Clear all items in storage
    clear: () => {
        localStorage.clear();
    },
}

export default storage;