// Imports
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Button, Grid, Typography, Toolbar,
    Box, Drawer, Stack, IconButton, FormControl, Input
} from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CONFIG } from '../utils/config';
import requestHandler from '../utils/requestHandler';
import storage from '../utils/storage';

const Login = () => {

    // For navigating to other pages
    const navigate = useNavigate()

    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState<string>();



    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');

        if (!userName || !password) {
            setError('Username and password are required')
            return;
        }


        const { LOGIN } = CONFIG.ACCOUNTS;
        try {
            // Call the requestHandler for login API
            const result = await requestHandler('POST', LOGIN, false, false, {
                email: userName,
                password: password,
            }, undefined, undefined);

            // Handle success response
            if (!result.hasError) {
                // Store the tokens (access and refresh) and userdetails 
                const { access, refresh, details } = result.response.data;
                storage.setItem('tokens', { access, refresh });
                storage.setItem('userDetail', details)

                navigate('/Dashboard');
            } else {
                setError(result.message)
            }
        } catch (error) {
            setError('Login failed. Please try again later.');
        }
    };

    // For handling logo sidebar
    const logoSidebarWidth = 460

    const logoSidebar = (
        <Grid item sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: { sm: logoSidebarWidth },
            backgroundColor: 'black'
        }}>
            <img
                src='movius-logo-digital-stacked-reversed.png'
                alt='Logo'
                style={{ width: '60%', objectFit: 'scale-down' }}
            />
        </Grid>
    )
    return (
        <Box style={{ height: '100vh' }}>

            {/* Render drawer for screen sizes: xs: 'none', sm: 'none', md: 'block' */}
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: logoSidebarWidth, bgcolor: '#1e1d31' },
                }}
                open
            >
                {logoSidebar}
            </Drawer>

            {/* Render login form container */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100vh',
                    backgroundColor: '#fff5f4',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    ml: { md: `${logoSidebarWidth}px` }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >

                    {/* Page heading */}
                    <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
                        Welcome!
                    </Typography>
                    <Toolbar />
                    <Typography component="h1" variant="h5" sx={{ mb: '20px' }}>
                        Login to your account here
                    </Typography>

                    {/* Render form */}
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>

                        {/* Form fields */}
                        <Stack spacing={2} direction='column' sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Input
                                        id="userName"
                                        name='userName'
                                        onChange={(e) => setUserName(e.target.value)}
                                        placeholder='Email*'
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: '1px solid gray',
                                            height: '40px',
                                            paddingLeft: '10px',
                                        }}
                                    />
                                </FormControl>

                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <div className='flex flex-row' style={{ width: '100%' }}>
                                        <Input
                                            id="password"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password*"
                                            autoComplete="off"
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: '1px solid gray',
                                                height: '40px',
                                                paddingLeft: '10px',
                                                width: '100%'
                                            }}
                                        />
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            style={{
                                                backgroundColor: 'transparent',
                                                height: '40px',
                                                width: '40px',
                                                marginLeft: '-40px'
                                            }}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </div>
                                </FormControl>
                            </Box>
                        </Stack>

                        <p style={{
                            textTransform: "none",
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            marginTop: '6px'
                        }}>
                            <Link to='/accounts/forgot-password' style={{ color: 'black', fontSize: '14px' }}>Forgot password?</Link>
                        </p>

                        <input type="hidden" name="_csrf" value="<%= csrfToken %>"></input>

                        {/* Submit button */}
                        <Box>
                            <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    borderRadius: '20px',
                                    backgroundColor: 'black',
                                    '&:hover': {
                                        backgroundColor: '#3b3b3b'
                                    }
                                }}
                            >Login</Button>
                        </Box>
                    </form>

                    {/* display error */}
                    <p style={{ fontSize: '16px', color: 'red' }}>{error}</p>
                </Box>
            </Box>
        </Box>
    );
}

export default Login