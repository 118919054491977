import React, { useEffect, useState } from 'react'
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'

// Column interface definition
interface Column {
    id: string
    label: string
    align?: 'left' | 'right' | 'center'
}

// Key-value pair type for flexibility
interface KeyValueType {
    [key: string]: any
}

// Type definition for flattened record
interface FlattenedRecord {
    flintNumber: string
    didNumber: string
    status: string
    bundle: string
    enterpriseId: string
    enterpriseName: string
    addons: string[]

}

interface Props {
    records: KeyValueType[]
    filters: KeyValueType | undefined
    setFilters: (result: KeyValueType) => void,
    totalRecordsCount: number | undefined,
    lastRefreshTime: string,
    loading: boolean,
    setLoading: (value: boolean) => void,
}

const RecordsTable: React.FC<Props> = ({ records, filters, setFilters, totalRecordsCount, lastRefreshTime, loading, setLoading }) => {
    const [columns] = useState<Column[]>([
        { id: 'enterpriseId', label: 'Customer ID' },
        { id: 'enterpriseName', label: 'Customer Name' },
        { id: 'flintNumber', label: 'Billing Number' },
        { id: 'didNumber', label: 'DID Number' },
        { id: 'status', label: 'Status' },
        { id: 'bundle', label: 'Bundle Name' },
    ])

    const [flattenedRecords, setFlattenedRecords] = useState<FlattenedRecord[]>();

    // Pagination state
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const flattenRecords = () => {
        setLoading(true);
        const flattened = records.map((record) => ({
            ...record,
            enterpriseId: record.enterprise?.universalId || "-",
            enterpriseName: record.enterprise?.name || "-",
        }));
        setFlattenedRecords(flattened as FlattenedRecord[]);
        setLoading(false);
    }

    // set the updated values to filters obj  
    useEffect(() => {
        setFilters((prevFilters: KeyValueType) => ({
            ...prevFilters,
            page: page + 1,
            limit: rowsPerPage,
        }));
    }, [page, rowsPerPage])

    useEffect(() => {
        flattenRecords();
    }, [records])

    return (
        <div style={{ marginTop: '40px' }}>
            <Box>
                {/* Render table */}
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {loading ? (
                                // skeleton loader when loading is true
                                Array.from({ length: rowsPerPage }).map((_, index) => (
                                    <TableRow key={index}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id}>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : ((flattenedRecords && flattenedRecords.length > 0) ? (
                                // Rendering table rows if data is available
                                flattenedRecords.map((row, index) => (
                                    <TableRow hover key={index}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>
                                                {Array.isArray(row[column.id as keyof FlattenedRecord])
                                                    ? (row[column.id as keyof FlattenedRecord] as string[]).join(', ') // Join the array if it's addons
                                                    : row[column.id as keyof FlattenedRecord] || '-'} {/* Render the value or '-' */}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        <Typography variant="h6" style={{ fontSize: '14px' }}>No Data Available</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ fontSize: '14px' }}>Last uploaded on (UTC): <span style={{ fontWeight: 'bold' }}>{lastRefreshTime}</span></p>
                    {/* Pagination */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={totalRecordsCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Box>
        </div>
    )
}

export default RecordsTable
