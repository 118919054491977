import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import './index.css';
import App from './App';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#2d2c47',
    },
    secondary: {
      main: '#36354a',
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Generate a random nonce
const nonce = process.env.REACT_APP_NONCE;
const cache = createCache({
  key: 'mui',
  nonce: nonce,
});

root.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);