import React, { useState } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    Alert,
    CircularProgress,
    Grid,
    Drawer
} from "@mui/material";
import { CONFIG } from "../../utils/config";
import requestHandler from "../../utils/requestHandler";

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState<string>("");

    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const logoSidebarWidth = 460

    const logoSidebar = (
        <Grid item sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: { sm: logoSidebarWidth },
            backgroundColor: 'black'
        }}>
            <img
                src='/movius-logo-digital-stacked-reversed.png'
                alt='Logo'
                style={{ width: '60%', objectFit: 'scale-down' }}
            />
        </Grid>
    )

    const handleForgotPassword = async (e: React.FormEvent) => {
        e.preventDefault();

        // Validate inputs
        if (!email) {
            setError("Email is required.");
            return;
        }

        setError("");
        setSuccess("");
        setLoading(true);

        try {
            const { FORGOT_PASSWORD } = CONFIG.ACCOUNTS;

            const result = await requestHandler('POST', FORGOT_PASSWORD, false, false, {
                email
            });

            if (!result.hasError) {
                setSuccess(`Password reset email sent successfully. Check your email for the next steps.`)
            } else {
                setError(result.message)
            }
        }
        catch (err) {
            setError("An error occurred while resetting the password. Contact Support!!");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box style={{ height: '100vh' }}>

            {/* Render drawer for screen sizes: xs: 'none', sm: 'none', md: 'block' */}
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: logoSidebarWidth, bgcolor: '#1e1d31' },
                }}
            >
                {logoSidebar}
            </Drawer>

            {/* Render login form container */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100vh',
                    backgroundColor: '#fff5f4',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    ml: { md: `${logoSidebarWidth}px` }
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: "100vh", padding: 2 }}
                >
                    <Typography variant="h4" gutterBottom>
                        Verify Email
                    </Typography>

                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

                    {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}


                    <Box
                        component="form"
                        onSubmit={handleForgotPassword}
                        sx={{ maxWidth: 400, width: "100%" }}
                    >
                        <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            sx={{ mt: 2 }}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : "Verify"}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ForgotPassword;
