import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    CircularProgress,
} from "@mui/material";
import { CONFIG } from "../utils/config";
import requestHandler from "../utils/requestHandler";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";

type AuditLog = {
    id: number;
    action: string;
    tableName: string;
    recordId: number;
    createdAt: string;
    user: Record<string, any>;
    detail: string;
};

interface Props {
    open: boolean,
    setOpen: (bool: boolean) => void
}

const AuditLogs: React.FC<Props> = ({ open, setOpen }) => {
    const [logs, setLogs] = useState<AuditLog[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>();

    const navigate = useNavigate();

    const fetchLogs = async () => {
        setLoading(true);
        setError(null);

        const { LOGS } = CONFIG.ACCOUNTS;
        try {
            const result = await requestHandler('GET', `${LOGS}?page=${page + 1}&limit=${rowsPerPage}`, true);
            if (!result.hasError) {
                setTotalCount(result.response.data.totalCount);
                setLogs(result.response.data.logs);
            } else if (result.status === 401) {
                navigate('/Login');
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error fetching admins:', err);
            setError('Failed to load admins. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    };

    useEffect(() => {
        fetchLogs();
    }, [page, rowsPerPage])

    return (
        <Layout heading={'Audit Logs'} open={open} setOpen={setOpen}>
            <div>
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <Paper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Detail</TableCell>
                                        <TableCell>Timestamp</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logs
                                        .map((log) => (
                                            <TableRow key={log.id}>
                                                <TableCell>{log.detail}</TableCell>
                                                <TableCell>{new Date(log.createdAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalCount || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}
            </div>
        </Layout>
    );
};

export default AuditLogs;
