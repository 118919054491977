// Imports
import React from 'react'
import { useEffect } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import Dashboard from './pages/Dashboard'
import Reports from './pages/Reports'
import Settings from './pages/Settings'
import Help from './pages/Help'
import Login from './pages/Login'
import Upload from './pages/Upload'
import Accounts from './pages/accounts'
import ResetPassword from './pages/accounts/reset-password'
import ForgotPassword from './pages/accounts/forgot-password'
import AuditLogs from './pages/Logs'
import ListHistoricalFiles from './components/accounts/ListHistoricalFiles'
import ListPartners from './components/accounts/ListPartner'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'


function App() {

  // Set page background color
  useEffect(() => {
    document.body.style.backgroundColor = '#f9f5f9'
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  // To render or not to render a page
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {/* Route definitions to different pages */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard open={open} setOpen={setOpen} />} />
          <Route path="/Reports" element={<Reports open={open} setOpen={setOpen} />} />
          <Route path="/Settings" element={<Settings open={open} setOpen={setOpen} />} />
          <Route path="/Help" element={<Help open={open} setOpen={setOpen} />} />
          <Route path="/Upload" element={<Upload open={open} setOpen={setOpen} />} />
          <Route path='/accounts/reset-password' element={<ResetPassword />} />
          <Route path='/accounts/forgot-password' element={<ForgotPassword />} />
          <Route path='/accounts' element={<Accounts open={open} setOpen={setOpen} />} />
          <Route path='/logs' element={<AuditLogs open={open} setOpen={setOpen} />} />
          <Route path='/historical-files' element={<ListHistoricalFiles open={open} setOpen={setOpen} />} />
          <Route path='/partners' element={<ListPartners open={open} setOpen={setOpen} />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
