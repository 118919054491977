// Imports
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FiltersPanel from "../components/FiltersPanel";
import storage from "../utils/storage";
import Layout from "../components/Layout";
import { CONFIG } from "../utils/config";
import requestHandler from "../utils/requestHandler";
import RecordsTable from "../components/RecordsTable";

const drawerWidth = 320;
// Interface for defining filters configuration type
interface KeyValueType {
  [key: string]: any;
}

// Part of type definition for props
interface Props {
  open: boolean,
  setOpen: (bool: boolean) => void
}

const Dashboard: React.FC<Props> = ({ open, setOpen }) => {

  // For navigating between pages
  const navigate = useNavigate()

  const [records, setRecords] = useState<KeyValueType[]>([])
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>();
  const [lastRefreshTime, setLastRefreshTime] = useState<string>();
  const [filters, setFilters] = useState<KeyValueType>()
  const [loading, setLoading] = useState<boolean>(true);

  // this will fetch the records 
  const fetchRecords = async () => {

    setLoading(true);
    const { LIST_RECORDS } = CONFIG.DATA_HANDLER;

    try {
      const queryParams = new URLSearchParams();

      // If there are filters, append them as query parameters
      if (filters) {
        Object.keys(filters).forEach(key => {
          if (filters[key] && key !== "showUnassignedFlint") {
            queryParams.append(key, encodeURIComponent(filters[key]));
          }
        });
      }

      const urlWithParams = `${LIST_RECORDS}?${queryParams.toString()}`;

      const result = await requestHandler('GET', urlWithParams, true);

      // Handle success response
      if (!result.hasError) {
        const data = result.response.data;
        let fetchedRecords = data.data;
        let totalCount = data.totalCount;

        // If `showUnassignedFlint` is true, filter the fetched records for unassigned ones
        if (filters && filters.showUnassignedFlint === "true") {
          fetchedRecords = fetchedRecords.filter((record: KeyValueType) => record.didNumber === "");
          totalCount = fetchedRecords.length;
        }
        setTotalRecordsCount(totalCount);
        setRecords(fetchedRecords);

        const date = new Date(data.lastRefreshTime);
        setLastRefreshTime(date.toLocaleString());
      } else if (result.status === 401) {
        setLoading(false)
        navigate('/Login');
      } else {
        setRecords([]);
        setTotalRecordsCount(0);
        setLoading(false);
      }
    } catch (error) {
      setRecords([])
      console.log('Unable to fetch records:', error);
    }
  }

  // redirect to login page if tokens not present 
  useEffect(() => {
    const tokens = storage.getItem('tokens');
    if (tokens == null) navigate('/Login');

    document.body.style.backgroundColor = '#f9f5f9'
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  useEffect(() => {
    fetchRecords();
  }, [filters])

  return (
    <Layout heading={'Flint Dashboard'} open={open} setOpen={setOpen}>
      <Stack
        direction='column'
        spacing={2}
      >
        {/* Render filters */}
        <FiltersPanel
          open={open}
          drawerWidth={drawerWidth}
          setFilters={setFilters}
        />

        {/* render results   */}
        <RecordsTable records={records} totalRecordsCount={totalRecordsCount} filters={filters} setFilters={setFilters} lastRefreshTime={lastRefreshTime as string} loading={loading} setLoading={setLoading} />
      </Stack>
    </Layout>

  );
}

export default Dashboard;