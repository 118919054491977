import { Snackbar, Alert } from "@mui/material";

const CustomSnackbar = ({ open, message, severity, onClose }: any) => (
    <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
        <Alert onClose={onClose} severity={severity || "success"} sx={{ width: "100%" }}>
            {message}
        </Alert>
    </Snackbar>
);

export default CustomSnackbar;
