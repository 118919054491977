import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box, CSSObject, Divider, Grid,
    IconButton, Theme,
    styled, useMediaQuery, useTheme
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Header from "./Header";
import SideBar from "./SideBar";
import storage from "../utils/storage";
import Footer from "./Footer";

const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xl')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface Props {
    open: boolean,
    setOpen: (bool: boolean) => void;
    heading: string;
    children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ heading, open, setOpen, children }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    // user states 
    const [isUserSuperadmin, setIsUserSuperadmin] = useState<boolean>(false);
    const [name, setName] = useState<string>();

    // Additional drawer code
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Screen size
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const [isViewportMediumOrLarger, setIsViewportMediumOrLarger] = useState(isMedium);

    useEffect(() => {
        setIsViewportMediumOrLarger(isMedium);
    }, [isMedium]);

    useEffect(() => {
        setOpen(false)
    }, [isViewportMediumOrLarger])

    useEffect(() => {
        const tokens = storage.getItem('tokens');
        if (tokens == null) navigate('/Login');

        const userDetail = storage.getItem('userDetail');
        if (userDetail) {
            try {
                const parsedUserDetail = JSON.parse(userDetail);
                setName(parsedUserDetail.firstName[0] + parsedUserDetail.lastName?.[0])
                if (parsedUserDetail.superadmin) {
                    setIsUserSuperadmin(true)
                }
            } catch (error) {
                console.error('Error parsing user detail:', error);
            }
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>

            {/* Render the Appbar */}
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    bgcolor: 'white',
                    boxShadow: 'none',
                    borderBottom: '1px solid lightgray'
                }}
            >
                <Header
                    title={heading}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    name={name}
                />
            </AppBar>

            {/* Render the sidebar */}
            <Drawer variant="permanent" open={open} sx={{
                width: open ? drawerWidth : 0,
                overflow: 'hidden',
                display: open ? 'block' : 'none',
                '& .MuiDrawer-paper': {
                    width: open ? drawerWidth : 0,
                    overflow: 'hidden',
                    bgcolor: '#1e1d31',
                    transition: 'width 0.5s ease',
                }
            }}>
                <DrawerHeader>
                    <Grid
                        container
                        spacing={2}
                        direction='row'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            item
                            xs={8}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <img
                                src={process.env.REACT_APP_SIDEBAR_LOGO}
                                alt='Logo'
                                style={{ width: '150px', height: '50px', objectFit: 'scale-down' }}
                            />
                        </Grid>
                    </Grid>
                    <IconButton onClick={handleDrawerClose} sx={{
                        color: 'white'
                    }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <SideBar
                    drawerWidth={drawerWidth}
                    title='Flint Dashboard'
                    open={open}
                    isUserSuperadmin={isUserSuperadmin}
                />
            </Drawer>


            <Box
                component="main"
                sx={{ display: 'flex', flexGrow: 1, minHeight: 'calc(100vh - 76px)', height: 'calc(100vh - 76px)', flexDirection: 'column', justifyContent: 'space-between', mt: { xs: '76px' } }}
            >
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    {children}
                </Box>

                <Box sx={{ marginTop: 'auto' }}>
                    <Footer />
                </Box>
            </Box>
        </Box >
    );
}

export default Layout;