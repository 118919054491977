import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Box,
    Checkbox,
    IconButton,
    Button,
    Tooltip,
    SelectChangeEvent,
    TablePagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';

import { CONFIG } from '../../utils/config';
import requestHandler from '../../utils/requestHandler';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import CustomSnackbar from '../common/CustomSnackbar';
import FormDialog from '../dialogs/FormDialog';
import storage from '../../utils/storage';

// Type definition for Admin data
interface Admin {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    superadmin: boolean;
    active: boolean;
    partner: Record<string, any>;
    password?: string;
    confirmPassword?: string;
    partnerId?: number | undefined;
}

interface RegisterFormData {
    firstName: string;
    lastName: string;
    email: string;
    superadmin: boolean;
    partnerId: number | undefined;
}

// type definition for partner data
interface Partner {
    id: number;
    name: string | number;
}

const ListAdmin: React.FC = () => {
    enum FormType {
        Register = 0,
        Edit = 1,
        ResetPassword = 2,
    }

    const [loading, setLoading] = useState<boolean>(true);
    const [isUserSuperadmin, setIsUserSuperadmin] = useState<boolean>(false);
    const [loggedInAdmin, setLoggedInAdmin] = useState<Admin>();

    // Pagination related states
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [totalAdminsCount, setTotalAdminsCount] = useState<number>();
    const [admins, setAdmins] = useState<Admin[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);

    // state for registering new admin
    const [registerFormData, setRegisterFormData] = useState<RegisterFormData>({
        firstName: '',
        lastName: '',
        email: '',
        superadmin: false,
        partnerId: undefined,
    });

    // states to display error & success messages
    const [error, setError] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();

    // states for modal handling
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [formType, setFormType] = useState<FormType>();
    const [selectedAdmin, setSelectedAdmin] = useState<Admin>();

    // states for deleting an admin
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [toBeDeleted, setToBeDeleted] = useState<number>();

    const navigate = useNavigate();

    // Fetch Admins list from API
    const fetchAdmins = async () => {
        setLoading(true);
        setError(null);

        const { ADMIN_HANLDER } = CONFIG.ACCOUNTS;
        try {
            const result = await requestHandler('GET', `${ADMIN_HANLDER}?page=${page + 1}&limit=${rowsPerPage}`, true);
            if (!result.hasError) {
                setTotalAdminsCount(result.response.data.totalCount);
                setAdmins(result.response.data.admins);
            } else if (result.status === 401) {
                navigate('/Login');
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error fetching admins:', err);
            setError('Failed to load admins. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // fetch partners list from API
    const fetchPartners = async () => {
        const { LIST_PARTNERS } = CONFIG.ACCOUNTS;
        try {
            const result = await requestHandler('GET', LIST_PARTNERS, true);

            // Handle success response
            if (!result.hasError) {
                setPartners(result.response.data);
            } else if (result.status === 401) {
                navigate('/Login');
            } else {
                setError(result.response.message);
            }
        } catch (error) {
            console.log('Unable to fetch records:', error);
        }
    }

    // register new admin form handler
    const registerFormHandleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }> | SelectChangeEvent<number>) => {
        const { name, value, type, checked } = e.target as HTMLInputElement;
        // update formData based on type of input
        if (name) {
            setRegisterFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    // function to register new admin
    const registerAdmin = async () => {
        const { ADMIN_HANLDER } = CONFIG.ACCOUNTS;
        try {
            if (!isUserSuperadmin) {
                registerFormData.partnerId = loggedInAdmin?.partnerId;
            }
            const processedData = Object.fromEntries(
                Object.entries({ ...registerFormData, ...(isUserSuperadmin ? {} : { partnerId: loggedInAdmin?.partnerId }) })
                    .filter(([key, value]) => value !== '')
            );
            // Call the requestHandler for login API
            const result = await requestHandler('POST', ADMIN_HANLDER, true, true, processedData);

            // Handle success response
            if (!result.hasError) {
                setModalOpen(false);
                setSnackbarMessage('Registered admin successfully.')
                setSnackbarOpen(true);
                setSelectedAdmin(undefined);
                setError("");
                fetchAdmins();
                setRegisterFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    superadmin: false,
                    partnerId: undefined,
                });
            } else if (result.status == 401) {
                navigate('/Login')
            } else {
                setError(result.message)
            }
        } catch (error) {
            setError('Admin registration failed. Please try again later.');
        }
    }

    // delete handler functions
    const handleDeleteClick = (id: number) => {
        setToBeDeleted(id);
        setShowConfirmDialog(true);
    };

    // delete admin api call handler 
    const deleteAdmin = async () => {
        const { ADMIN_HANLDER } = CONFIG.ACCOUNTS;
        try {
            const result = await requestHandler('DELETE', `${ADMIN_HANLDER}/${toBeDeleted}`, true);

            if (!result.hasError) {
                setAdmins((prevAdmins) => prevAdmins.filter((admin) => admin.id !== toBeDeleted));
                setSnackbarMessage('Admin deleted successfully!')
                setSnackbarOpen(true);
                setError("");
                fetchAdmins();
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error deleting admin:', err);
            setError('Failed to delete admin. Please try again later.');
        }
        setShowConfirmDialog(false);
    };

    // Handle changes in the form modal
    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<number>) => {
        const { name, value, type, checked } = e.target as HTMLInputElement;

        if (!selectedAdmin) return;

        const newValue = value === "none" || value === undefined ? "" : value;

        setSelectedAdmin({
            ...selectedAdmin,
            [name]: type === 'checkbox' ? checked : newValue
        });
    };

    // Save edited admin
    const editAdmin = async () => {
        const { ADMIN_HANLDER } = CONFIG.ACCOUNTS;
        if (!selectedAdmin) return;

        const processedData = Object.fromEntries(
            Object.entries({ ...selectedAdmin, ...(selectedAdmin.partnerId ? { partnerId: selectedAdmin.partnerId } : (selectedAdmin.partner && { partnerId: selectedAdmin.partner.id })) })
                .filter(([key, value]) => value !== '')
        );

        try {
            const result = await requestHandler('PUT', `${ADMIN_HANLDER}/${selectedAdmin.id}`, true, false, processedData);

            if (!result.hasError) {
                setAdmins((prevAdmins) =>
                    prevAdmins.map((admin) => (admin.id === selectedAdmin.id ? selectedAdmin : admin))
                );
                setModalOpen(false);
                setSnackbarMessage('Admin edited successfully.')
                setSnackbarOpen(true);
                setSelectedAdmin(undefined);
                setError("");
                fetchAdmins();
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error updating admin:', err);
            setError('Failed to update admin. Please try again later.');
        }
    };

    // If the logged-in user initiates the request for self account, the reset password form is displayed.  
    // For all other users, a password reset email is triggered instead.  
    const resetPasswordBtnHandler = async (selfAccount: boolean, admin: Admin) => {
        setSelectedAdmin(admin);

        if (selfAccount) {
            setModalOpen(true);
            setFormType(FormType.ResetPassword);
            return;
        }

        const { INITIATE_RESET_PASSWORD } = CONFIG.ACCOUNTS;

        try {
            const result = await requestHandler('GET', `${INITIATE_RESET_PASSWORD}/${admin.id}`, true);

            if (!result.hasError) {
                setSnackbarMessage('Password reset email sent successfully.')
                setSnackbarOpen(true);
                setSelectedAdmin(undefined);
                setError("")
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error on reset password request:', err);
            setError('Failed to trigger reset password. Please try again later.');
        }
    }


    // reset password form submit handler
    const resetPassword = async () => {
        const { RESET_PASSWORD } = CONFIG.ACCOUNTS;
        if (!selectedAdmin) return;

        if (!selectedAdmin.password || !selectedAdmin.confirmPassword) {
            setError('Please fill both the fields.')
            return;
        }

        if (selectedAdmin.password !== selectedAdmin.confirmPassword) {
            setError('Passwords do not match!')
            return;
        }
        try {
            const result = await requestHandler('POST', `${RESET_PASSWORD}`, true, false, selectedAdmin);

            if (!result.hasError) {
                setAdmins((prevAdmins) =>
                    prevAdmins.map((admin) => (admin.id === selectedAdmin.id ? selectedAdmin : admin))
                );
                setModalOpen(false);
                setSnackbarMessage('Password reset successfully.')
                setSnackbarOpen(true);
                setSelectedAdmin(undefined);
                setError("")
            } else {
                setError(result.response.message);
            }
        } catch (err) {
            console.error('Error updating admin:', err);
            setError('Failed to update admin. Please try again later.');
        }
    }

    useEffect(() => {
        fetchAdmins();

        const tokens = storage.getItem('tokens');
        if (tokens == null) navigate('/Login');

        const userDetail = storage.getItem('userDetail');
        if (userDetail) {
            try {
                const parsedUserDetail = JSON.parse(userDetail);
                setLoggedInAdmin(parsedUserDetail);
                if (parsedUserDetail.superadmin) {
                    setIsUserSuperadmin(true)
                    fetchPartners();
                }
            } catch (error) {
                console.error('Error parsing user detail:', error);
            }
        }
    }, [])

    // clear the fields once form modal is closed or open
    useEffect(() => {
        if (modalOpen == false) {
            setRegisterFormData({
                firstName: '',
                lastName: '',
                email: '',
                superadmin: false,
                partnerId: undefined,
            });
            setError('');
        }
    }, [modalOpen])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    };

    useEffect(() => {
        fetchAdmins();
    }, [page, rowsPerPage])

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ margin: 'auto' }}>
            {error &&
                <Typography color="error" align="center" sx={{ mt: 4 }}>
                    {error}
                </Typography>
            }
            {/* register admin btn */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setModalOpen(true);
                        setFormType(FormType.Register);
                    }}
                >
                    Register Admin
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Active</TableCell>
                            {
                                isUserSuperadmin &&
                                <>
                                    <TableCell>Superadmin</TableCell>
                                    <TableCell>Partner</TableCell>
                                </>
                            }
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>


                    <TableBody>
                        {admins.map((admin) => (
                            <TableRow key={admin.id}>
                                <TableCell>
                                    {(() => {
                                        const fullName = `${admin.firstName} ${admin.lastName}`;
                                        return (
                                            <span title={fullName} style={{ cursor: 'default' }}>
                                                {fullName.length > 50 ? `${fullName.substring(0, 40)}...` : fullName}
                                            </span>
                                        );
                                    })()}
                                </TableCell>
                                <TableCell>{admin.email}</TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={admin.active}
                                        disabled
                                        color="primary"
                                    />
                                </TableCell>

                                {
                                    isUserSuperadmin &&
                                    <>
                                        <TableCell>
                                            <Checkbox
                                                checked={admin.superadmin}
                                                disabled
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell>{admin.partner?.name || 'N/A'}</TableCell></>
                                }
                                <TableCell>
                                    <Tooltip title="Edit">
                                        <IconButton color="primary" onClick={() => {
                                            setSelectedAdmin(admin);
                                            setModalOpen(true);
                                            setFormType(FormType.Edit);
                                        }}>
                                            <EditIcon sx={{ fontSize: '22px' }} />
                                        </IconButton>
                                    </Tooltip>
                                    {isUserSuperadmin &&
                                        <Tooltip title="Delete">
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleDeleteClick(admin.id)}
                                            >

                                                <DeleteIcon sx={{ fontSize: '22px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {(isUserSuperadmin || (loggedInAdmin && (admin.id == loggedInAdmin.id))) &&
                                        (<Tooltip title="Reset password">
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    setSelectedAdmin(admin);
                                                    resetPasswordBtnHandler(((admin.id == loggedInAdmin?.id) ? true : false), admin)
                                                }}
                                            >
                                                <LockResetIcon sx={{ fontSize: '22px' }} />
                                            </IconButton>
                                        </Tooltip>)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={totalAdminsCount || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* success message div */}
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />

            {/* Modal to register admin */}
            <FormDialog
                open={modalOpen && formType == FormType.Register}
                onClose={() => setModalOpen(false)}
                title={'Register Admin'}
                error={error}
                onChange={registerFormHandleChange}
                onSubmit={registerAdmin}
                fields={[
                    {
                        name: "firstName",
                        label: "First name",
                        value: registerFormData?.firstName,
                        type: 'text',
                        mandatory: true
                    },
                    {
                        name: "lastName",
                        label: "Last name",
                        value: registerFormData?.lastName,
                        type: 'text',
                        mandatory: true
                    },
                    {
                        name: "email",
                        label: "Email",
                        value: registerFormData?.email,
                        type: 'email',
                        mandatory: true
                    },
                    {
                        name: "superadmin",
                        label: "Superadmin",
                        value: registerFormData?.superadmin,
                        type: 'checkbox',
                        hide: isUserSuperadmin ? false : true,
                    },
                    {
                        name: 'partnerId',
                        label: "Partner",
                        value: registerFormData?.partnerId,
                        type: 'dropdown',
                        dropdownValues: partners,
                        hide: isUserSuperadmin ? false : true,
                        disabled: registerFormData?.superadmin,
                        mandatory: !registerFormData?.superadmin
                    }
                ]}
            />

            {/* Modal to reset password */}
            <FormDialog
                open={modalOpen && formType == FormType.ResetPassword}
                onClose={() => setModalOpen(false)}
                title={'Reset password'}
                error={error}
                onChange={handleChange}
                onSubmit={resetPassword}
                fields={[
                    {
                        name: "password",
                        label: "Password",
                        type: 'password',
                    },
                    {
                        name: "confirmPassword",
                        label: "Confirm Password",
                        type: 'password',
                    },
                ]}
            />

            {/* Modal to edit admin */}
            <FormDialog
                open={modalOpen && formType == FormType.Edit}
                onClose={() => setModalOpen(false)}
                title={'Edit Admin'}
                error={error}
                onChange={handleChange}
                onSubmit={editAdmin}
                fields={[
                    {
                        name: "firstName",
                        label: "First name",
                        value: selectedAdmin?.firstName,
                        type: 'text',
                        mandatory: true
                    },
                    {
                        name: "lastName",
                        label: "Last name",
                        value: selectedAdmin?.lastName,
                        type: 'text',
                        mandatory: true
                    },
                    {
                        name: "email",
                        label: "Email",
                        value: selectedAdmin?.email,
                        type: 'email',
                        mandatory: true
                    },
                    {
                        name: "superadmin",
                        label: "Superadmin",
                        value: selectedAdmin?.superadmin,
                        type: 'checkbox',
                        hide: isUserSuperadmin ? false : true
                    },
                    {
                        name: "active",
                        label: "Active",
                        value: selectedAdmin?.active,
                        type: 'checkbox'
                    },
                    {
                        name: 'partnerId',
                        label: "Partner",
                        value: selectedAdmin?.partnerId || selectedAdmin?.partner?.id,
                        type: 'dropdown',
                        dropdownValues: partners,
                        hide: isUserSuperadmin ? false : true,
                        disabled: selectedAdmin?.superadmin,
                        mandatory: !selectedAdmin?.superadmin
                    }
                ]}
            />

            {/* Confirmation modal for deletion */}
            <ConfirmDialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                title={'Confirm Delete'}
                content={'Are you sure you want to delete this admin?'}
                onConfirm={deleteAdmin}
            />

        </Box>
    );
};

export default ListAdmin;
