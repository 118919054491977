export const CONFIG = {
    ACCOUNTS: {
        LOGIN: '/api/accounts/login',
        ADMIN_HANLDER: '/api/accounts/admin',
        LIST_PARTNERS: '/api/accounts/partner',
        FORGOT_PASSWORD: '/api/accounts/forgot-password',
        INITIATE_RESET_PASSWORD: '/api/accounts/initiate-reset-password',
        RESET_PASSWORD: '/api/accounts/reset-password',
        LOGS: '/api/accounts/logs',
        SSO_HANDLER: '/api/accounts/sso',
    },
    DATA_HANDLER: {
        UPLOAD_FILE: '/api/data/upload',
        LIST_RECORDS: '/api/data/records',
        HISTORICAL_FILES: '/api/data/historical-files',
        HISTORICAL_FILE_BY_ID: '/api/data/historical-file'
    },
}