import axios, { AxiosRequestConfig } from 'axios';
import storage from './storage';

// Base URL for the API
const API_BASE_URL = process.env.REACT_APP_API_HOST;

/**
 * Generic server request handler
 * 
 * @param method - HTTP method (e.g., 'GET', 'POST', 'PUT', 'DELETE')
 * @param endpoint - API endpoint (relative to the base URL)
 * @param useToken - Boolean flag to determine if a token is required
 * @param body - Request payload for POST, PUT, DELETE (optional for GET)
 * @param params - Query parameters (optional)
 * @returns Promise with the API response data in a standardized format
 */
const requestHandler = async (
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    endpoint: string,
    useToken: boolean = false, // Default to no token
    isFormData: boolean = false,
    body?: Record<string, any>,
    params?: Record<string, any>,
    token?: string,
): Promise<{ status: number; response: any; message: string; hasError: boolean }> => {
    try {
        // Check if API_HOST is defined
        if (!API_BASE_URL) {
            throw new Error('API_HOST is not defined in the environment variables.');
        }

        let accessToken: string | null = null;

        // Retrieve the token from storage if `useToken` is true
        if (useToken) {
            const tokens = storage.getItem('tokens');
            if (tokens) {
                const parsedTokens = JSON.parse(tokens);
                accessToken = parsedTokens?.access || null;
            }
        } else if (token) {
            accessToken = token;
        }

        // Create the request config
        const config: AxiosRequestConfig = {
            method,
            url: `${API_BASE_URL}${endpoint}`,
            headers: {
                ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
                ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
                // ...(csrfToken && { 'X-CSRF-Token': csrfToken })
            },
            withCredentials: true,
            ...(body && { data: body }),
            ...(params && { params }),
        };

        // Make the API request
        const response = await axios(config);

        // Standardized success response
        return {
            status: response.status,
            response: response.data,
            message: 'Request successful',
            hasError: false,
        };
    } catch (error: any) {
        console.error('API Request Error:', error);

        let status = 500;
        let message = 'Unexpected error occurred.';
        let response = null;

        if (error.response) {
            // Server responded with a status outside the 2xx range
            console.error('Response Error:', error.response.data);
            status = error.response.status;
            message = error.response.data.message || 'Something went wrong with the request.';
            response = error.response.data;
        } else if (error.request) {
            // Request was made but no response was received
            console.error('No Response:', error.request);
            message = 'No response from the server. Please try again later.';
        } else {
            // Something else happened
            message = error.message || 'Unexpected error occurred.';
        }

        // Standardized error response
        return {
            status,
            response,
            message,
            hasError: true,
        };
    }
};

export default requestHandler;
